<template>
	<div class="relative main-h-screen flex">
		<div class="w-full flex">
			<div id="faq_questions" class="hidden md:block bg-green-100 text-black w-1/4 border-r-2 border-green-300" style="background: #f2f2f2; min-width: 25%; max-width: 25%">
				<h4 class="text-2xl font-bold text-black pt-5 px-3">{{ currentQestions.meta.title }}</h4>
				<nav class="pt-5 capitalize w-full">
					<router-link
						v-for="(item, index) in currentQestions.children"
						:to="{ name: item.name }"
						class="block py-5 px-3 border-b-2 border-gray-200 hover:bg-gray-200 hover:text-green-600 border-t-2"
						:key="index"
						>{{ item.meta.title }}
					</router-link>
				</nav>
			</div>
			<div id="faq_questions" class="md:hidden sidenav" :class="{ sidenavHidden: !$store.state.mobileMenuOpen }" style="background: #f2f2f2; margin-top: 96px">
				<h4 class="text-2xl font-bold text-black px-3">{{ currentQestions.meta.title }}</h4>
				<nav class="pt-5 capitalize w-full" style="min-width: 250px">
					<router-link
						v-for="(item, index) in currentQestions.children"
						:to="{ name: item.name }"
						class="block py-5 px-3 border-b-2 border-gray-200 hover:bg-gray-200 hover:text-green-600 border-t-2"
						:key="index"
						@click.native="$store.state.mobileMenuOpen = false"
						>{{ item.meta.title }}
					</router-link>
				</nav>
			</div>
			<div id="faq_body" class="w-full">
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>

<script>
import { routes } from '../router';
export default {
	data() {
		return {
			title: this.$route?.meta?.title,
			routes: routes || []
		};
	},
	computed: {
		questionsKeys() {
			return Object.keys(this.faq_questions);
		},
		currentQestions() {
			const category = this.$route?.matched[0]?.name;
			const routeQuestionsObject = this.routes.find((route) => route.name === category);
			return routeQuestionsObject || {};
		}
	}
};
</script>

<style scoped>
.sidenav {
	height: 100%;
	width: 250px;
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	background-color: #111;
	overflow-x: hidden;
	padding-top: 60px;
	/* min-width: min-content; */
	min-width: 300px;
	max-width: 100vw;
	transition: 0.5s;
	max-height: calc(100% - 96px);
}
.sidenavHidden {
	width: 0 !important;
	min-width: 0;
}
</style>
